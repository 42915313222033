import React from "react";
import './Proceedings.css'
import Layout from "../layout/Layout";
import Footer from "../../components/footer/Footer";

const Proceedings= () => {
  return (
    <>
      <Layout/>
      <h2 className="proceedings-title">PROCEEDINGS</h2>
      <div className="proceedings">
        
      </div>
      <Footer/>
      
    </>
  );
};

export default Proceedings;
