
import image2 from '../assets/Speakers/Amir zadeh.jpg'
import image4 from '../assets/Speakers/Eric Dutt.jpg'

export default [
    {
      name:'Amir zadeh',
      role:'Senior Consultant at Capgemini and Ph.D. Candidate at University of Gothenburg',
      image:image2
    },
    {
      name:'Eric Dutt',
      role:'AI Change Agent at AI Swedenn',
      image:image4
    }
]
